import PageBanner from "../components/PageBanner";
import banner from "../assets/images/social-enterprise/banner.jpg";
import {
  Container,
  Box,
  Heading,
  SimpleGrid,
  Text,
  VStack,
  Image,
} from "@chakra-ui/react";
import Header from "../components/Header";
import GroupTask from "../assets/images/social-enterprise/Group Task.png";
import OnlineSupport from "../assets/images/social-enterprise/Online Support.png";
import Protect from "../assets/images/social-enterprise/Protect.png";
import AboutContainer from "../components/AboutContainer";
import FadeIn from "../components/FadeIn";
import MediaAppearances from "../components/MediaAppearances";
import Value from "../components/Value";

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context(
    "../assets/images/social-enterprise/image-carousel",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

export default function SocialEnterprise() {
  const Different = () => {
    return (
      <Box bg="purple.10">
        <Container maxW="7xl" py={5}>
          <VStack align="left" spacing={3}>
            <Header
              subh={"Why we're different"}
              h={[
                "It's an approach we call ",
                <Heading as="span" color="purple.100">
                  radical dignity.
                </Heading>,
              ]}
              textAlign="left"
              align="left"
            />
            <SimpleGrid
              columns={{ base: 1, md: 3 }}
              gap={{ md: 3 }}
            >
              <Value
                head={"Support"}
                desc={
                  "By building positive relationships with vulnerable community members residing near your business, we can minimize disruptions, compassionately handle crises and keep the area clean and safe."
                }
                image={OnlineSupport}
              />
              <Value
                head={"Safety"}
                desc={
                  "Our staff are trained in opioid poisoning, crisis de-escalation, and first aid response. Providing skills and training to the most vulnerable members of our community is how we support lasting change."
                }
                image={Protect}
              />
              <Value
                head={"Social Impact"}
                desc={
                  "Through peer relationships, training programs, and judgement-free employment, our staff gain confidence and can further integrate into the community."
                }
                image={GroupTask}
              />
            </SimpleGrid>
          </VStack>
        </Container>
      </Box>
    );
  };

  const Gallery = () => {
    return (
      <Container maxW={"7xl"} py={5}>
        <VStack w="100%" align={"left"}>
          <FadeIn>
            <Heading>Gallery</Heading>
          </FadeIn>
          <FadeIn>
            <Text>Select photos of the RadiCare team at work</Text>
          </FadeIn>
          <FadeIn>
            <MediaAppearances items={images} />
          </FadeIn>
        </VStack>
      </Container>
    );
  };

  return (
    <>
      <FadeIn>
        <PageBanner
          title="RadiCare Ventures"
          subtitle="RadiCare Ventures is a social enterprise offering litter, needle, and waste disposal services in Calgary."
          image={banner}
          fadeIn={true}
          link={{
            display: "RadiCare Website",
            url: "https://www.radicareventures.ca/",
          }}
        />
      </FadeIn>
      <AboutContainer
        head="About RadiCare"
        desc={[
          "RadiCare is a social enterprise offering litter, needle, and waste disposal services in Calgary. We hire people with barriers to employment and provide them with professional development opportunities. This helps us support vulnerable communities, minimize negative interactions with the public, and offer property maintenance in a whole new way.",
          <br />,
          <br />,
          "As experienced members of the not-for-profit, harm prevention, and social work fields, our leadership is well versed in both first aid and crisis response. We work with shelters and existing employment programs in Calgary to get the right talent on board.‍",
          <br />,
          <br />,
          "Matching leaders who have worked with vulnerable communities with those experiencing barriers to work helps us both stabilize our staff members' lives and leave customers with a job well done.",
        ]}
      />
      <Different />
      <Gallery />
    </>
  );
}
