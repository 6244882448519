import {
  Container,
  VStack,
  Image,
  Box,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import FadeIn from "../../components/FadeIn";
import trailer from "../../assets/images/mobile-hygiene/trailer.png";
import AboutContainer from "../../components/AboutContainer";
import Timeline from "../../components/Timeline/Timeline";

export default function MobileHygiene() {
  const Hero = () => {
    return (
      <Box bg={"purple.10"}>
        <Container maxW={"7xl"} py={5}>
          <Flex
            justify={"space-between"}
            align={"center"}
            flexWrap={"wrap"}
            gap={5}
          >
            <VStack spacing={2} align={"left"} flex={1}>
              <Heading>Mobile Hygiene</Heading>
              <Text>
                RadiCalgary aims to make basic hygiene more accessible through
                mobile hygiene units
              </Text>
            </VStack>
            <FadeIn maxW={{ base: "100%", md: "650px" }}>
              <Image src={trailer} maxH={"300px"} objectFit={"cover"} />
            </FadeIn>
          </Flex>
        </Container>
      </Box>
    );
  };

  const Message = () => {
    return (
      <Box bg="purple.500" py={5} color={"white"}>
        <Container maxW="7xl">
          <VStack textAlign={"center"}>
            <FadeIn>
              <Heading size="lg">
                People experiencing homelessness in the City of Calgary have
                limited access to showers and hygiene services. We aim to change
                that.
              </Heading>
            </FadeIn>
          </VStack>
        </Container>
      </Box>
    );
  };
  return (
    <>
      <Hero />
      <Message />
      <AboutContainer
        head="About Mobile Hygiene"
        desc={[
          "RadiCalgary plans to address the barriers faced by people experiencing daily challenges in navigating accessible hygiene services in Calgary. Modeled after LavaMae’s Mobile Hygiene Toolkit, RadiCalgary encompasses radical hospitality, radical compassion, radical dignity, and radical acceptance in providing low-barrier shower, washroom, and laundry services to people experiencing vulnerabilities.",
          <br />,
          <br />,
          "The portability of a mobile hygiene unit allows us to bring services to where they are needed, through community needs assessments and partnerships with community stakeholders.",
        ]}
        bg="gray.50"
      />
      <Timeline />
    </>
  );
}
