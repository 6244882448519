import PageBanner from "../../components/PageBanner";
import banner from "../../assets/images/cpucv/banner.jpg";
import {
  VStack,
  Image,
  Text,
  Container,
  Box,
  SimpleGrid,
  Heading,
} from "@chakra-ui/react";
import PeopleServed from "../../assets/images/cpucv/people-served.png";
import Vendors from "../../assets/images/cpucv/vendors.png";
import ItemsProvided from "../../assets/images/cpucv/items-provided.png";
import Partners from "../../assets/images/cpucv/partners.png";
import FadeIn from "../../components/FadeIn";
import MediaAppearances from "../../components/MediaAppearances";
import AboutContainer from "../../components/AboutContainer";

const stats = [
  {
    name: "People Per Event",
    value: "700",
    icon: PeopleServed,
  },
  {
    name: "Vendors",
    value: "50+",
    icon: Vendors,
  },
  {
    name: "Items Provided",
    value: "1000+",
    icon: ItemsProvided,
  },
  {
    name: "Sponsors",
    value: "10+",
    icon: Partners,
  },
];

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context(
    "../../assets/images/cpucv/image-carousel",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

const Stats = () => {
  return (
    <Container maxW="7xl" display={"flex"} justifyContent={"center"} py={5}>
      <SimpleGrid columns={{ base: 1, sm: 2, lg: 4 }} w="100%">
        {stats.map((stat) => (
          <FadeIn>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Image src={stat.icon} h="160px" />
              <Text fontWeight={"bold"} fontSize="2xl">
                {stat.value}
              </Text>
              <Text>{stat.name}</Text>
            </Box>
          </FadeIn>
        ))}
      </SimpleGrid>
    </Container>
  );
};

const About = () => {
  return (
    <AboutContainer
      head="About the C-PUCV"
      desc={[
        "The Calgary Pop-Up Care Village (C-PUCV) is a festival for anyone experiencing systemic vulnerabilities such as homelessness, addiction, mental health issues, poverty, language and cultural barriers, and more. The festival provides equitable access to basic services such as case management, healthcare, clothing, haircuts, food, and more to the community. This is a one-day event that upholds guests' inherent human dignity, and it bridges the wider Calgarian community with the underserved population.",
        <br />,
        <br />,
        "The program runs twice a year, and if you are interested in learning more about C-PUCV programmings, please visit the website at c-pucv.ca.",
      ]}
      bg="gray.50"
    />
  );
};

const Gallery = () => {
  return (
    <Container maxW={"7xl"} py={5}>
      <VStack w="100%" align={"left"}>
        <FadeIn>
          <Heading>Gallery</Heading>
        </FadeIn>
        <FadeIn>
          <Text>Select photos from our past events</Text>
        </FadeIn>
        <FadeIn>
          <MediaAppearances items={images} />
        </FadeIn>
      </VStack>
    </Container>
  );
};

export default function Cpucv() {
  return (
    <>
      <PageBanner
        title="Calgary Pop-Up Care Village"
        subtitle="The Calgary Pop-Up Care Village is a festival for anyone experiencing systemic vulnerabilities such as homelessness and addiction."
        image={banner}
        backgroundPosition="50% 25%"
        fadeIn={true}
        link={{ display: "C-PUCV Website", url: "https://c-pucv.ca/" }}
      />
      <Stats />
      <About />
      <Gallery />
    </>
  );
}
