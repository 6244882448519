import { extendTheme } from "@chakra-ui/react";
import "./assets/css/fonts.css";

const theme = extendTheme({
  fonts: {
    heading: `'Merriweather', sans-serif`,
    body: `'Poppins', sans-serif`,
  },
  fontSizes: {
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
    "2xl": "24px",
    "3xl": "28px",
    "4xl": "36px",
    "5xl": "48px",
    "6xl": "64px",
  },
  colors: {
    gray: {
      50: "#f2f2f2",
      100: "#e5e5e5",
      200: "#d9d9d9",
      300: "#cccccc",
      400: "#BFBFBF",
      500: "#999999",
      600: "#737373",
      700: "#4c4c4c",
      800: "#393939",
      900: "#262626",
    },
    purple: {
      10: "#F2E8F1",
      50: "#b078af",
      100: "#a362a2",
      200: "#954b95",
      300: "#883587",
      400: "#7b1e7a",
      500: "#6f1b6e",
      600: "#621862",
      700: "#561555",
      800: "#4a1249",
      900: "#3e0f3d",
    },
  },
  components: {
    Text: {
      baseStyle: {
        fontSize: "md",
      },
      variants: {
        section: {
          fontSize: "xl",
          fontWeight: "semi",
        },
      },
    },
    Button: {
      baseStyle: {
        fontSize: "md",
        fontWeight: "normal",
        padding: "2 3 2 3",
        outline: "none",
      },
      variants: {
        outline: {
          borderWidth: "1px",
        },
        white: {
          bg: "white",
          color: "purple",
          _hover: {
            bg: "gray.100",
          },
        },
      },
    },
    Input: {
      variants: {
        default: {
          field: {
            borderRadius: "5px",
            backgroundColor: "white",
            borderColor: "gray.400",
            borderStyle: "solid",
            borderWidth: "1px",
            _placeholder: { color: "gray.400" },
            p: "10px",
          },
        },
      },
    },
    Divider: {
      baseStyle: {
        borderWidth: "1px",
        borderColor: "gray.400",
      },
    },
    Heading: {
      variants: {
        sans: {
          fontFamily: "body",
        },
      },
    },
    Accordion: {
      variants: {
        container: {
          button: {
            border: "none",
            bg: "purple.500",
            color: "white",
            fontWeight: "bold",
            borderRadius: "5px",
            _expanded: {
              borderRadius: "5px 5px 0 0 ",
              py: 2,
              transition: "padding 0.2s",
              bg: "white",
              color: "black",
              border: "1px solid",
              borderColor: "gray.400",
              borderBottom: 0,
            },
            p: 3,
          },
          panel: {
            p: 3,
          },
          container: {
            border: "none",
          },
        },
      },
    },
  },
  space: {
    px: "1px",
    1: "5px",
    2: "10px",
    3: "20px",
    4: "40px",
    5: "80px",
  },
  breakpoints: {
    timeline: "1170px"
  },
});

export default theme;
