import { VStack, Heading } from "@chakra-ui/react";
import FadeIn from "./FadeIn";

export default function Header({
  subh,
  h,
  align = "center",
  textAlign = "center",
}) {
  return (
    <VStack align={align} spacing={3} textAlign={textAlign}>
      <FadeIn>
        <Heading
          variant={"sans"}
          size="sm"
          color="purple"
          textTransform={"uppercase"}
        >
          {subh}
        </Heading>
      </FadeIn>
      <FadeIn>
        <Heading>{h}</Heading>
      </FadeIn>
    </VStack>
  );
}
