import {
  Container,
  Heading,
  VStack,
  Text,
  Link,
  Button,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
export default function PageNotFound() {
  return (
    <Container
      height={{ base: "calc(100vh - 91px)", md: "calc(100vh - 112px)" }}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <VStack>
        <Heading>Error 404</Heading>
        <Text>Page not found</Text>
        <Button
          colorScheme="purple"
          as="a"
          href="/"
          rightIcon={<ArrowForwardIcon />}
        >
          Return Home
        </Button>
      </VStack>
    </Container>
  );
}
