import { useInView } from "framer-motion";
import { useRef } from "react";
import { motion } from "framer-motion";
import { Box } from "@chakra-ui/react";

export default function FadeIn(props) {
  const ref = useRef();
  const isInView = useInView(ref, { once: true });

  return (
    <Box
      style={{
        opacity: isInView ? 1 : 0,
        transition: "all 1.2s",
      }}
      ref={ref}
      {...props}
    >
      {props.children}
    </Box>
  );
}
