import {
  Grid,
  GridItem,
  Heading,
  VStack,
  Text,
  HStack,
  Image,
  Box,
  LinkBox,
  LinkOverlay,
  IconButton,
} from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { useState } from "react";
import { Autoplay } from "swiper/modules";
import { useSwiper } from "swiper/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

export const SwiperNav = () => {
  const swiper = useSwiper();

  return (
    <HStack spacing={2} marginTop={3}>
      <IconButton
        borderRadius="50%"
        icon={<ChevronLeftIcon />}
        bg={"none"}
        border={"1px solid"}
        onClick={() => swiper.slidePrev()}
        isDisabled={!swiper.allowSlidePrev}
      />
      <IconButton
        borderRadius="50%"
        icon={<ChevronRightIcon />}
        bg={"none"}
        border={"1px solid"}
        onClick={() => swiper.slideNext()}
        isDisabled={!swiper.allowSlideNext}
      />
    </HStack>
  );
};

function Article({ data }) {
  return (
    <LinkBox>
      <LinkOverlay href={data.url} isExternal>
        <VStack spacing={3}>
          <Image
            src={data.image}
            height={"200px"}
            borderRadius="5px"
            objectFit={"cover"}
            objectPosition={"center"}
            w={"100%"}
          />
          <VStack spacing={1} alignItems={"left"}>
            <Text color="purple.400" textTransform={"uppercase"}>
              {data.author} {data.author && "|"} {data.affiliation}
            </Text>
            <Heading fontSize={"lg"} noOfLines={2} variant="sans">
              {data.title}
            </Heading>
            {/* <Text noOfLines={3}>{data.desc}</Text> */}
          </VStack>
        </VStack>
      </LinkOverlay>
    </LinkBox>
  );
}

export default function MediaAppearances({ items }) {
  const [isEndScroll, setIsEndScroll] = useState("r");
  const [activeIndex, setActiveIndex] = useState(-1);
  return (
    <Swiper
      modules={[Autoplay]}
      spaceBetween={"20px"}
      slidesPerView={1}
      style={{ maxWidth: "100%", zIndex: 0 }}
      breakpoints={{
        768: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 3,
        },
      }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
    >
      <SwiperNav />
      {items.map((item, index) => (
        <SwiperSlide
          key={index}
          onMouseEnter={() => setActiveIndex(index)}
          onMouseLeave={() => setActiveIndex(-1)}
          // style={(activeIndex !== index && activeIndex !== -1) ? { filter: "blur(5px)", transition: "filter 0.5s" } : {}}
        >
          {typeof item === "string" ? (
            <Image
              src={item}
              height={"250px"}
              borderRadius="5px"
              objectFit={"cover"}
              objectPosition={"center"}
              w={"100%"}
            />
          ) : (
            <Article data={item} />
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
