import { Text, Box, Container, Grid, Heading, VStack } from "@chakra-ui/react";
import FadeIn from "./FadeIn";

export default function AboutContainer({ head, desc, bg }) {
  return (
    <Box bg={bg}>
      <Container maxW="7xl" py={5}>
        <Grid templateColumns={{ base: "1fr", md: "1fr 5fr" }} gap={4}>
          <FadeIn>
            <Heading>{head}</Heading>
          </FadeIn>
          <VStack align={"left"}>
            <FadeIn>
              <Text>{desc}</Text>
            </FadeIn>
          </VStack>
        </Grid>
      </Container>
    </Box>
  );
}
