import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Container,
  Box,
  Heading,
  Text,
  VStack,
  Button,
} from "@chakra-ui/react";

export default function PageBanner(props) {
  const title = props.title;
  const subtitle = props.subtitle;
  const image = props.image;
  const link = props.link;
  return (
    <Box
      backgroundSize={"cover"}
      backgroundPosition={
        props.backgroundPosition ? props.backgroundPosition : "center"
      }
      backgroundImage={
        "linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.6) 100%)," +
        "url('" +
        image +
        "')"
      }
      height={"400px"}
      backgroundAttachment={{ base: "scroll", md: "fixed" }}
      backgroundRepeat={"no-repeat"}
    >
      <Container
        maxW="7xl"
        height={"inherit"}
        display={"flex"}
        justifyContent={"center"}
        flexDirection={"column"}
        alignItems={"center"}
        textAlign={"center"}
      >
        <VStack spacing={2}>
          <Heading color="white">{title}</Heading>
          <Text color="white">{subtitle}</Text>
          {link && (
            <Button
              colorScheme="purple"
              as="a"
              href={link.url}
              rightIcon={<ExternalLinkIcon />}
            >
              {link.display}
            </Button>
          )}
        </VStack>
      </Container>
    </Box>
  );
}
