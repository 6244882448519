import {
  Text,
  Heading,
  Container,
  VStack,
  Button,
  Grid,
  Image,
  Box,
  Link,
  SimpleGrid,
  calc,
  HStack,
  Flex,
} from "@chakra-ui/react";
import hero from "../assets/images/home/hero.jpg";
import cpucvLogo from "../assets/images/cpucv-logo.png";
import radicareLogo from "../assets/images/radicare-logo.png";
import radicalgaryLogo from "../assets/images/mobile-hygiene-logo.png";
import MediaAppearances from "../components/MediaAppearances";
import AboutUsImg from "../assets/images/home/about-us-bg.jpg";
import FadeIn from "../components/FadeIn";
import article1 from "../assets/images/home/article1.png";
import article2 from "../assets/images/home/article2.jpeg";

const appearances = [
  {
    title:
      "RadiCalgary places 2nd in Samsung Canada’s 2022/23 Solve for Tomorrow Contest",
    affiliation: "Samsung Canada",
    image: article1,
    url: "https://news.samsung.com/ca/triage-kiosk-wins-samsung-canadas-2022-23-solve-for-tomorrow-contest",
  },
  {
    title: "Working to provide barrier-free hygiene services in Calgary",
    affiliation: "Enough For All",
    image: article2,
    url: "https://enoughforall.ca/articles/working-to-provide-barrier-free-hygiene-services-in",
  },
];

const Service = ({ title, desc, image, href }) => {
  return (
    <Box maxH="400px" p={3}>
      <VStack spacing={2} align={"center"}>
        <Image src={image} h={"75px"} />
        <Heading size="sm" variant={"sans"}>
          {title}
        </Heading>
        <Text textAlign={"center"}>{desc}</Text>
        <Link href={href}>
          <Button colorScheme="purple">Learn More</Button>
        </Link>
      </VStack>
    </Box>
  );
};

const Hero = () => {
  return (
    <Box
      backgroundPosition={{ base: "80% 15%", md: "50% 50%" }}
      backgroundImage={
        "linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0) 100%)," +
        "url('" +
        hero +
        "')"
      }
      backgroundRepeat={"no-repeat"}
      backgroundSize={"cover"}
      height={{ base: "calc(100vh - 91px)", md: "calc(100vh - 112px)" }}
    >
      <Container
        maxW="7xl"
        paddingY={{ md: "200px" }}
        display={"flex"}
        alignItems={"center"}
        h="100%"
      >
        <VStack maxW="2xl" align="left" spacing={3}>
          <FadeIn>
            <Heading color="white" as="h1">
              RadiCalgary brings hygiene services & pop-up festivals to
              Calgarians
            </Heading>
          </FadeIn>
          <FadeIn>
            <Text color="white">
              Our mission is to promote the dignity and wellbeing for anyone
              experiencing poverty in Calgary by offering hygiene and wraparound
              services through radical hospitality and compassion.
            </Text>
          </FadeIn>
          <Flex gap={3} flexWrap={"wrap"}>
            <FadeIn w={{ base: "100%", md: "fit-content" }}>
              <Button variant="white" w={"100%"} as="a" href="/about">
                Learn More
              </Button>
            </FadeIn>
            <FadeIn w={{ base: "100%", md: "fit-content" }}>
              <Button
                variant="outline"
                color="white"
                _hover={{ color: "purple.500", bg: "white" }}
                w={"100%"}
                as="a"
                href="https://www.paypal.com/donate/?hosted_button_id=277LGX498TX36"
              >
                Donate
              </Button>
            </FadeIn>
          </Flex>
        </VStack>
        <Image />
      </Container>
    </Box>
  );
};

const Services = () => {
  return (
    <Box position={"relative"}>
      <Container maxW="7xl" py={5}>
        <FadeIn>
          <Heading textAlign="center" size="lg">
            Here is how we plan to change that...
          </Heading>
        </FadeIn>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={2}>
          <FadeIn>
            <Service
              title={"Mobile Hygiene"}
              desc={
                "We aim to promote the dignity and wellbeing for anyone experiencing poverty by offering hygiene services"
              }
              image={radicalgaryLogo}
              href={"/programs-and-services/mobile-hygiene"}
            />
          </FadeIn>
          <FadeIn>
            <Service
              title={"Pop-Up Care Village"}
              desc={
                "A festival for anyone experiencing systemic vulnerabilities such as homelessness and addiction."
              }
              image={cpucvLogo}
              href={"/programs-and-services/c-pucv"}
            />
          </FadeIn>
          <FadeIn>
            <Service
              title={"RadiCare Ventures"}
              desc={
                "A social enterprise offering litter, needle, and waste disposal services with a focus on hiring people with barriers to employment."
              }
              image={radicareLogo}
              href={"/social-enterprise"}
            />
          </FadeIn>
        </SimpleGrid>
      </Container>
    </Box>
  );
};

const About = () => {
  return (
    <Box
      backgroundImage={
        "linear-gradient(0deg, rgba(123, 30, 122, 0.7), rgba(123, 30, 122, 0.7)), url('" +
        AboutUsImg +
        "')"
      }
      backgroundPosition={"center"}
      backgroundSize={"cover"}
      color="white"
    >
      <Container maxW="7xl" py={5}>
        <Grid templateColumns={{ base: "1fr", md: "1fr 3fr" }} gap={4}>
          <FadeIn>
            <Heading size="lg">Who Are We</Heading>
          </FadeIn>
          <VStack align={"left"}>
            <FadeIn>
              <Text>
                Our vision at RadiCalgary is to foster a community where
                individuals are treated as people first. To help us reach this
                vision, we are built under four fundamental values: radical
                hospitality, radical compassion, radical dignity, and radical
                acceptance.
              </Text>
            </FadeIn>
            <FadeIn>
              <Button w="min-content" variant="white" as="a" href="/about">
                About Us
              </Button>
            </FadeIn>
          </VStack>
        </Grid>
      </Container>
    </Box>
  );
};

const Media = () => {
  return (
    <Box>
      <Container maxW="7xl" py={5}>
        <FadeIn>
          <Heading size="lg">See Us in the News</Heading>
        </FadeIn>
        <FadeIn>
          <MediaAppearances items={appearances} />
        </FadeIn>
      </Container>
    </Box>
  );
};

const Problem = () => {
  return (
    <Box bg="purple.10">
      <Container maxW="7xl" py={5}>
        <FadeIn>
          <Heading size="lg" textAlign={"center"}>
            100% of NPO staff agree that there is a need for greater
            accessibility to shower and hygiene services in Calgary
          </Heading>
        </FadeIn>
      </Container>
    </Box>
  );
};
export default function Home() {
  return (
    <>
      <Hero />
      <Problem />
      <Services />
      <About />
      <Media />
    </>
  );
}
