import { Route, Routes } from "react-router-dom";
import Home from "./Routes/Home";
import Cpucv from "./Routes/programs_and_services/Cpucv";
import About from "./Routes/About";
import SocialEnterprise from "./Routes/SocialEnterprise";
import MobileHygiene from "./Routes/programs_and_services/MobileHygiene";
import PageNotFound from "./Routes/PageNotFound";

function App() {
  return (
    <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/about" exact element={<About />} />
      <Route path="/programs-and-services/c-pucv" exact element={<Cpucv />} />
      <Route
        path="/programs-and-services/mobile-hygiene"
        exact
        element={<MobileHygiene />}
      />
      <Route path="/social-enterprise" exact element={<SocialEnterprise />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default App;
