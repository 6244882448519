import jsonp from "jsonp";
import {
  Flex,
  HStack,
  InputGroup,
  Input,
  Button,
  FormControl,
  Spinner,
  Text,
  Alert,
  AlertIcon,
  Box,
  AlertDescription,
  CloseButton,
  AlertTitle,
  Container,
  Fade,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import { useState } from "react";

export default function SignUpForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [showStatusMessage, setShowStatusMessage] = useState(false);
  const onSubmit = async (values) => {
    setIsLoading(true);
    const url =
      "https://gmail.us21.list-manage.com/subscribe/post?u=fc90f3d064fa5cf62760fad7c&amp;id=aab94a4ea4&amp;f_id=00dadfe6f0";

    const response = await fetch(
      `${url}&FNAME=${values.name}&EMAIL=${values.email}`,
      {
        method: "POST",
        mode: "no-cors",
      }
    )
      .then(() => {
        setIsLoading(false);
        setIsSuccessful(true);
      })
      .catch(() => {
        setIsLoading(false);
        setIsSuccessful(false);
      });
    setShowStatusMessage(true);
    setTimeout(() => setShowStatusMessage(false), 2000);
  };

  return (
    <>
      <Fade in={showStatusMessage}>
        {showStatusMessage && (
          <Alert
            status={isSuccessful ? "success" : "error"}
            position={"fixed"}
            top={0}
            w="100vw"
            left={0}
            right={0}
            as={Container}
            maxW="7xl"
            visibility={!showStatusMessage && "hidden"}
            borderBottomRadius={"lg"}
            zIndex={999}
          >
            <AlertIcon />
            <Box w="100%">
              <AlertTitle>{isSuccessful ? "Success!" : "Error!"}</AlertTitle>
              <AlertDescription>
                {isSuccessful
                  ? "You have been successfully subscribed!"
                  : "An error has been encountered. Please try again."}
              </AlertDescription>
            </Box>
            <CloseButton
              alignSelf="flex-start"
              position="relative"
              right={-1}
              top={-1}
              onClick={() => {
                setShowStatusMessage(false);
              }}
            />
          </Alert>
        )}
      </Fade>
      <Formik
        initialValues={{
          name: null,
          email: null,
        }}
        onSubmit={onSubmit}
      >
        <Form>
          <Flex
            flexDir={{ base: "column", sm: "row" }}
            alignItems={"center"}
            mt={{ base: 3, lg: 0 }}
          >
            <HStack spacing={0}>
              <FormControl maxW={{ sm: "150px" }}>
                <Field
                  name="name"
                  id="name"
                  as={Input}
                  variant="default"
                  placeholder={"Name"}
                  borderRadius={{
                    base: "5px 0 0 5px",
                    sm: "5px 0 0 5px",
                  }}
                ></Field>
              </FormControl>
              <FormControl maxW={{ sm: "150px" }}>
                <Field
                  name="email"
                  id="email"
                  as={Input}
                  variant="default"
                  type="email"
                  placeholder={"Email"}
                  borderRadius={{
                    base: "0 5px 5px 0",
                    sm: "none",
                  }}
                  borderLeft={"none"}
                ></Field>
              </FormControl>
            </HStack>
            <Button
              mt={{ base: 3, sm: 0 }}
              w={{ base: "100%", sm: "min-content" }}
              colorScheme="purple"
              borderRadius={{ base: "5px", sm: "0 5px 5px 0" }}
              isDisabled={isLoading}
              type="submit"
            >
              {isLoading ? <Spinner /> : "Subscribe"}
            </Button>
          </Flex>
        </Form>
      </Formik>
    </>
  );
}
