import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import {
  Heading,
  ListItem,
  Text,
  UnorderedList,
  Box,
  Container,
  Stack,
  SimpleGrid,
  Link,
  Button,
  List,
  VStack,
} from "@chakra-ui/react";
import {
  FaSearch,
  FaMoneyBillWave,
  FaRocket,
  FaCalendarPlus,
  FaCalendarWeek,
  FaCalendarAlt,
  FaPeopleCarry,
} from "react-icons/fa";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import "./Timeline.css";

const timelineItems = [
  {
    date: "DEC 2022 - MAR 2023",
    title: "Discovery Phase",
    description: (
      <UnorderedList>
        <ListItem>
          Needs assessment: service provider and lived experience surveys
        </ListItem>
        <ListItem>
          Business plan: reaching out to service providers for potential
          partnerships
        </ListItem>
        <ListItem>
          Board of advisors: formalize a group of subject matter experts for
          direction finding
        </ListItem>
      </UnorderedList>
    ),
  },
  {
    date: "APR 2023 - JUL 2023",
    title: "Funding and Program Development",
    description: (
      <UnorderedList>
        <ListItem>
          Securing funding: applying for grants, connecting with philanthropists
          and funders
        </ListItem>
        <ListItem>
          Liaise with the City of Calgary and Water Services to address
          logistics of water usage, parking permits
        </ListItem>
        <ListItem>
          Formalize financial reporting standards, governance practices,
          internal controls, etc. to meet the requirements for registered
          non-profit organizations
        </ListItem>
      </UnorderedList>
    ),
  },
  {
    date: "AUG 2023 - SEP 2023",
    title: "Shower Trailer Pilot Project",
    description: (
      <UnorderedList>
        <ListItem>
          Rent a shower trailer (3 stalls) for a 2 month "proof of concept"
          pilot project.
        </ListItem>
        <ListItem>Partner with various NPOs for service hours</ListItem>
        <ListItem>
          Partner with BIA/BRZs during off-service hours to provide public
          washroom access
        </ListItem>
        <ListItem>
          Hire a washroom coordinator for off-service hour washroom oversight
        </ListItem>
        <ListItem>Recruit volunteers for our service hours</ListItem>
      </UnorderedList>
    ),
  },
  {
    date: "OCT 2023 - DEC 2023",
    title: "Evaluation Period",
    description: (
      <UnorderedList>
        <ListItem>
          Analyze the data collected to measure the success of the pilot project
          and determine next steps
        </ListItem>
        <ListItem>
          Release the report to the community and various stakeholders and gauge
          interest
        </ListItem>
      </UnorderedList>
    ),
  },
];
export default function Timeline() {
  const TimelineItem = ({ date, title, step, description }) => {
    return (
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date={
          <VStack align={"right"}>
            <Text>{date}</Text>
            <Heading
              variant={"sans"}
              display={{ base: "none", timeline: "block" }}
            >
              {title}
            </Heading>
          </VStack>
        }
        contentStyle={{
          boxShadow: "none",
          background: "none",
          fontSize: "16px",
          padding: 0,
        }}
        contentArrowStyle={{
          visibility: "hidden",
        }}
        iconStyle={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#F2E8F1",
          boxShadow: "none",
        }}
        icon={<Heading textAlign={"center"}>{step}</Heading>}
        position="right"
      >
        <Heading variant={"sans"} display={{ base: "block", timeline: "none" }}>
          {title}
        </Heading>
        {description}
      </VerticalTimelineElement>
    );
  };
  return (
    <Box>
      <Container maxW="7xl" py={5}>
        <Stack spacing={3} alignItems="center">
          <Heading textAlign={"center"} size="lg">
            Timeline Goals
          </Heading>
          <VerticalTimeline lineColor="#F2E8F1">
            {timelineItems.map((item, index) => (
              <TimelineItem
                date={item.date}
                title={item.title}
                step={index + 1}
                description={item.description}
              />
            ))}
          </VerticalTimeline>
        </Stack>
      </Container>
    </Box>
  );
}
