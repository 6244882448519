import {
  Container,
  Heading,
  VStack,
  Text,
  Image,
  Box,
  Flex,
  Divider,
  SimpleGrid,
  SlideFade,
} from "@chakra-ui/react";
import hero from "../assets/images/about/hero.jpg";
import FadeIn from "../components/FadeIn";
import aboutImage from "../assets/images/about/aboutimage.png";
import billZheng from "../assets/images/our-team/billzheng.jpg";
import hannaWoodward from "../assets/images/our-team/hannawoodward.jpg";
import hannaKim from "../assets/images/our-team/hannahkim.jpg";
import kristenCampbell from "../assets/images/our-team/kristencampbell.webp";
import tithi from "../assets/images/our-team/tithi.jpg";
import ronaldjin from "../assets/images/our-team/ronaldjin.png";
import nancyhuynh from "../assets/images/our-team/nancyhuynh.png";
import { useState } from "react";
import Header from "../components/Header";
import Value from "../components/Value";
import acceptance from "../assets/images/about/acceptance.svg";
import compassion from "../assets/images/about/compassion.svg";
import dignity from "../assets/images/about/dignity.svg";
import hospitality from "../assets/images/about/hospitality.svg";

const leadershipTeam = [
  {
    name: "Bill Zheng",
    title: "Co-chair & Co-founder",
    about:
      "Bill is a first year nursing student. He is passionate about homelessness, addiction, and providing dignifying care to people. He has experience working in the homeless serving sector working in both emergency shelter and outreach.",
    image: billZheng,
  },
  {
    name: "Hanna Woodward, RSW",
    title: "Co-chair & Co-founder",
    about:
      "Hanna is a registered social worker and fourth year Bachelor of Social Work student. She has 3 years of experience in the homeless serving sector working in permanent supportive housing, emergency shelter, and outreach.",
    image: hannaWoodward,
  },
  {
    name: "Hannah Kim",
    title: "Board Member & Media Coordinator",
    about:
      "Hannah is a first year biomedical science student. She is passionate about helping the homeless and community health.",
    image: hannaKim,
  },
  {
    name: "Kristen Campbell",
    title: "Board Member",
    about:
      "Kristen has a BA (Hons.) from McMaster University and a BComm from the University of Calgary’s Haskayne School of Business. She is an active volunteer in the homeless serving sector.",
    image: kristenCampbell,
  },
  {
    name: "Tithi Patel",
    title: "Board Member",
    about:
      "Tithi is a second year nursing student. She is enthusiastic about promoting equitability and compassion to people who experience homelessness. She has experience in harm reduction.",
    image: tithi,
  },
];

const websiteTeam = [
  {
    name: "Ronald Jin",
    title: "Front-End Developer",
    about:
      "Ronald is a software developer who is currently attending the University of Waterloo.",
    image: ronaldjin,
    imageStyle: "avatar",
  },
  {
    name: "Nancy Huynh",
    title: "UI/UX Designer",
    about:
      "Nancy is a product designer who is currently attending the University of Waterloo.",
    image: nancyhuynh,
    imageStyle: "avatar",
  },
];

export default function About() {
  const Hero = () => {
    return (
      <Box bg={"purple.10"}>
        <Container maxW={"7xl"} py={5}>
          <VStack spacing={3} textAlign={"center"}>
            <Header
              subh={"ABOUT US"}
              h={
                "Our vision at RadiCalgary is to foster a community where individuals are treated as people first."
              }
            />
            <FadeIn w="100%">
              <Image src={hero} h={"500px"} w={"100%"} objectFit={"cover"} />
            </FadeIn>
          </VStack>
        </Container>
      </Box>
    );
  };

  const ImageDescription = () => {
    return (
      <Container maxW={"7xl"} py={5}>
        <Flex flexDir={{ base: "column", lg: "row" }} flexWrap={"wrap"} gap={5}>
          <VStack spacing={3} flex={1} align={"left"}>
            <FadeIn>
              <Heading size="lg">Our Mission</Heading>
            </FadeIn>
            <FadeIn>
              <Text>
                RadiCalgary Society (RadiCalgary) is a provincially registered
                nonprofit organization based out of Calgary, Alberta.
                RadiCalgary currently hosts the Calgary Pop-Up Care Village
                (C-PUCV), a festival designed for people experiencing systemic
                vulnerabilities like homelessness, addiction, mental health
                concerns, language barriers, and food insecurity. The C-PUCV
                currently runs twice a year and they promote harmony and joy in
                the community.
                <br />
                <br />
                Additionally, RadiCalgary operates a social enterprise, RadiCare
                Ventures. RadiCare Ventures hire people who experience
                employment barriers to perform property maintenance tasks.
                Revenues generated by RadiCare Ventures goes toward the
                operation of RadiCalgary.
              </Text>
            </FadeIn>
          </VStack>
          <FadeIn>
            <Image src={aboutImage} alignSelf={"center"} />
          </FadeIn>
        </Flex>
      </Container>
    );
  };

  const Different = () => {
    return (
      <Box bg="purple.10">
        <Container maxW="7xl" py={5}>
          <VStack align="left" spacing={3}>
            <Header
              subh={"Our Vision"}
              h={[
                "Fostering a community where individuals are treated as ",
                <Heading as="span" color="purple.100">
                  people first.
                </Heading>,
              ]}
              textAlign="left"
              align="left"
            />
            <SimpleGrid columns={{ base: 1, md: 2 }} gap={3}>
              <Value
                head={"Radical Hospitality"}
                desc={
                  "Coined by LavaMaeX - meeting people where they are at and providing extraordinary care as people experiencing poverty deserves more than the bare minimum."
                }
                image={hospitality}
              />
              <Value
                head={"Radical Acceptance"}
                desc={"Accept people as who they are without judgement"}
                image={acceptance}
              />
              <Value
                head={"Radical Compassion"}
                desc={
                  "A trauma-informed practice that revolves around non-judgement and open-heartedness "
                }
                image={compassion}
              />
              <Value
                head={"Radical Dignity"}
                desc={
                  "Seeing people as unique, autonomous individuals who have the right and power to make decisions best fitted for them."
                }
                image={dignity}
              />
            </SimpleGrid>
          </VStack>
        </Container>
      </Box>
    );
  };

  const TeamMember = ({ member }) => {
    const [isHover, setIsHover] = useState(false);
    return (
      <VStack align={"left"} spacing={2}>
        <Box
          position={"relative"}
          overflow={"hidden"}
          backgroundImage={member.image}
          backgroundSize={member?.imageStyle === "avatar" ? "contain" : "cover"}
          backgroundRepeat={"no-repeat"}
          bg={!member.image && "gray.200"}
          h="300px"
          w="100%"
          borderRadius={"md"}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <SlideFade
            pos={"absolute"}
            top={0}
            in={isHover}
            style={{
              backgroundColor: "rgb(111, 27, 110,0.5)",
              padding: "20px",
              width: "100%",
              height: "100%",
            }}
          >
            <Box>
              <Text color="white">{member.about}</Text>
            </Box>
          </SlideFade>
        </Box>
        <Box>
          <Heading variant={"sans"} size="md">
            {member.name}
          </Heading>
          <Text>{member.title}</Text>
        </Box>
      </VStack>
    );
  };

  const OurTeam = () => {
    return (
      <>
        <Box bg="purple.500" py={5} color={"white"}>
          <Container maxW="7xl">
            <VStack textAlign={"center"}>
              <FadeIn>
                <Heading size="lg">
                  Meet the team working hard to promote the dignity and
                  wellbeing for those experiencing poverty
                </Heading>
              </FadeIn>
            </VStack>
          </Container>
        </Box>
        <Container
          maxW="7xl"
          py={5}
          paddingTop={{ base: "112px", md: "153px" }}
        >
          <VStack spacing={3} align={"left"}>
            <FadeIn>
              <Heading size="lg">Board of Directors</Heading>
            </FadeIn>
            <SimpleGrid
              columns={{ base: 1, sm: 2, md: 3, lg: 4 }}
              gap={3}
              w="100%"
            >
              {leadershipTeam.map((member) => (
                <FadeIn>
                  <TeamMember member={member} />
                </FadeIn>
              ))}
            </SimpleGrid>
            <Divider />
            <FadeIn>
              <Heading size="lg">Website Team</Heading>
            </FadeIn>
            <SimpleGrid
              columns={{ base: 1, sm: 2, md: 3, lg: 4 }}
              gap={3}
              w="100%"
            >
              {websiteTeam.map((member) => (
                <FadeIn>
                  <TeamMember member={member} />
                </FadeIn>
              ))}
            </SimpleGrid>
          </VStack>
        </Container>
      </>
    );
  };

  return (
    <>
      <Hero />
      <ImageDescription />
      <Different />
      <OurTeam />
    </>
  );
}
