import FadeIn from "./FadeIn";
import { Box, Image, VStack, Heading, Text } from "@chakra-ui/react";

export default function Value({ head, desc, image }) {
  return (
    <FadeIn mb={"30px"}>
      <Box
        bg="white"
        borderRadius={"md"}
        h="100%"
        p={3}
        position={"relative"}
        top={"30px"}
        display={"flex"}
        flexDir={"column"}
        flex={1}
      >
        <VStack top={"-50px"} pos={"relative"} align="left" spacing={3}>
          <Box justifyContent={"center"} display={"flex"}>
            <Box
              height={"60px"}
              width={"60px"}
              borderRadius={"50%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              border={"1px solid"}
              borderColor={"gray.400"}
              bg="white"
              overflow={"hidden"}
            >
              <Image src={image} maxW="45px" maxH="45px" />
            </Box>
          </Box>
          <Box>
            <Heading variant="sans" size="sm">
              {head}
            </Heading>
            <Text>{desc}</Text>
          </Box>
        </VStack>
      </Box>
    </FadeIn>
  );
}
