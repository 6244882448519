import {
  Container,
  Box,
  Flex,
  Image,
  HStack,
  Divider,
  VStack,
  Text,
  LinkBox,
  LinkOverlay,
  Button,
  Icon,
  Link,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  Grid,
  DrawerCloseButton,
  SimpleGrid,
  List,
  ListItem,
} from "@chakra-ui/react";
import cpucvLogo from "../assets/images/cpucv-logo.png";
import radicareLogo from "../assets/images/radicare-logo.png";
import radicalgaryLogo from "../assets/images/radicalgary-small.png";
import radicalgaryLogoLarge from "../assets/images/radicalgary-logo.png";
import { FaLinkedinIn, FaInstagram } from "react-icons/fa";
import {
  HamburgerIcon,
  CloseIcon,
  ArrowRightIcon,
  LinkIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ArrowBackIcon,
  ArrowForwardIcon,
} from "@chakra-ui/icons";
import { useDisclosure, Collapse } from "@chakra-ui/react";
import { useState, useEffect } from "react";

export const links = [
  {
    name: "Home",
    href: "/",
  },
  {
    name: "About",
    href: "/about",
  },
  {
    name: "Programs & Services",
    children: [
      {
        name: "Mobile Hygiene",
        href: "/programs-and-services/mobile-hygiene",
      },
      {
        name: "Calgary Pop-Up Care Village",
        href: "/programs-and-services/c-pucv",
      },
    ],
  },
  {
    name: "Social Enterprise",
    href: "/social-enterprise",
  },
];

const subsidaries = [
  {
    name: "RadiCalgary",
    focused: true,
    href: "/",
    logo: radicalgaryLogo,
  },
  {
    name: "Calgary Pop-Up Care Village",
    focused: false,
    href: "https://c-pucv.ca/",
    logo: cpucvLogo,
  },
  {
    name: "RadiCare Ventures",
    focused: false,
    href: "https://www.radicareventures.ca/",
    logo: radicareLogo,
  },
];

export const socialMedia = [
  {
    name: "LinkedIn",
    href: "https://www.linkedin.com/company/radicalgary",
    icon: FaLinkedinIn,
  },
  {
    name: "Instagram",
    href: "https://www.instagram.com/radi.calgary/",
    icon: FaInstagram,
  },
];

const donateLink = "#";
const contactLink = "#";

export default function NavBar() {
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <Box
      w="100%"
      transition-property="all"
      transition-timing-function="cubic-bezier(0.4, 0, 0.2, 1)"
      transition-duration="500ms"
      zIndex={"999"}
    >
      {/* <Collapse in={scrollDirection !== "down"} animateOpacity={false}> */}
      <Box
        borderBottom="1px"
        borderStyle={"solid"}
        borderColor={"gray.400"}
        bg="white"
      >
        <Box bg="purple.900">
          <Container maxW="7xl" display={"flex"}>
            {subsidaries.map((s) => (
              <HStack
                bg={s.focused && "white"}
                color={!s.focused && "white"}
                py={1}
                px={2}
                spacing={2}
                as="a"
                href={s.href}
                borderTopRadius={"md"}
                _hover={
                  !s.focused && {
                    bg: "purple.700",
                  }
                }
                alignItems={"center"}
              >
                <Box>
                  <Image
                    src={s.logo}
                    h="20px"
                    filter={!s.focused && "brightness(0) invert(1)"}
                  />
                </Box>
                <Text fontSize={"sm"} display={{ base: "none", md: "block" }}>
                  {s.name}
                </Text>
              </HStack>
            ))}
          </Container>
        </Box>
        <Container maxW="7xl">
          <Flex justify={"space-between"}>
            <HStack spacing={"2"}>
              <LinkBox>
                <LinkOverlay href="/">
                  <Image src={radicalgaryLogoLarge} h="30px" />
                </LinkOverlay>
              </LinkBox>
            </HStack>
            <Box display={{ base: "none", md: "block" }}>
              <DesktopNav />
            </Box>
            <HStack spacing={"2"} display={{ base: "none", xl: "flex" }}>
              <Button
                colorScheme="purple"
                variant={"outline"}
                as="a"
                href={contactLink}
              >
                Contact
              </Button>
              <Button colorScheme="purple" as="a" href={donateLink}>
                Donate
              </Button>
            </HStack>
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? (
                  <CloseIcon w={3} h={3} />
                ) : (
                  <HamburgerIcon w={5} h={5} />
                )
              }
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
              display={{ base: "block", md: "none" }}
              my={2}
            />
          </Flex>
        </Container>
      </Box>
      {/* </Collapse> */}
      <Drawer isOpen={isOpen} onClose={onClose} placement="right">
        <MobileNav />
      </Drawer>
    </Box>
  );
}

const DesktopSubNav = ({ children }) => {
  const [selected, setSelected] = useState(children[0]);

  useEffect(() => {
    setSelected(children[0]);
  }, [children]);

  return (
    <Box
      bg="white"
      p={3}
      borderBottomRadius={"md"}
      border={"1px solid"}
      borderColor={"gray.400"}
    >
      <Grid templateColumns="1fr auto 2fr" gap={3}>
        <VStack alignItems={"left"}>
          {children.map((child) => (
            <Button
              variant={
                child === selected && "children" in child ? "solid" : "outline"
              }
              border="none"
              colorScheme="purple"
              display={"flex"}
              justifyContent={"space-between"}
              onClick={() => setSelected(child)}
              as="a"
              href={child.href}
              px={2}
            >
              <Text>{child.name}</Text>
              {"children" in child ? <ArrowRightIcon /> : <LinkIcon />}
            </Button>
          ))}
        </VStack>
        <Divider orientation="vertical" />
        {selected && selected.children && selected.children.length > 0 && (
          <VStack spacing={2} align={"left"}>
            <Text fontWeight={"bold"} color="purple">
              {selected.name}
            </Text>
            <SimpleGrid columns={5}>
              {selected.children.map((c) => (
                <Link href={c.href}>{c.name}</Link>
              ))}
            </SimpleGrid>
          </VStack>
        )}
      </Grid>
    </Box>
  );
};

const DesktopNav = () => {
  const NavItem = ({ link }) => {
    const [isSelected, setIsSelected] = useState(false);

    return link.children && link.children.length > 0 ? (
      <ListItem
        onMouseEnter={() => {
          setIsSelected(true);
        }}
        onMouseLeave={() => {
          setIsSelected(false);
        }}
        key={link.name}
      >
        <Button
          borderRadius={0}
          px={"15px"}
          py={10}
          color={isSelected && "purple"}
          bg="white"
        >
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Text>{link.name}</Text>
            {isSelected ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </Flex>
        </Button>
        <Container
          position="absolute"
          marginLeft={"auto"}
          marginRight={"auto"}
          left={0}
          right={0}
          maxW="7xl"
          p={0}
        >
          <Collapse in={isSelected} animateOpacity>
            <DesktopSubNav children={link.children} />
          </Collapse>
        </Container>
      </ListItem>
    ) : (
      <ListItem>
        <Button
          as="a"
          href={link.href}
          borderRadius={0}
          bg="white"
          px={"15px"}
          py={10}
        >
          {link.name}
        </Button>
      </ListItem>
    );
  };

  return (
    <HStack spacing={3}>
      <List display={"flex"}>
        {links.map((link) => (
          <NavItem link={link} />
        ))}
      </List>
    </HStack>
  );
};

const MobileNav = () => {
  const [curItem, setCurItem] = useState(links);
  const [travelHistory, setTravelHistory] = useState([]);

  return (
    <Box fontSize={"lg"}>
      <DrawerOverlay />
      <DrawerContent w="300px" pos={"relative"}>
        <Flex
          px={4}
          py={2}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {travelHistory.length > 0 ? (
            <Link
              color={"purple"}
              onClick={() => setCurItem(travelHistory.pop())}
            >
              <ArrowBackIcon />
              Back
            </Link>
          ) : (
            <Image src={radicalgaryLogoLarge} h={"30px"} />
          )}
          <DrawerCloseButton />
        </Flex>
        <Divider />
        <Box px={4} py={2}>
          <VStack spacing={2} align="left">
            {curItem.map((link) =>
              link.children && link.children.length > 0 ? (
                <Link
                  href={link.href && link.href}
                  onClick={(e) => {
                    e.preventDefault();
                    setTravelHistory((travelHistory) => [
                      ...travelHistory,
                      curItem,
                    ]);
                    setCurItem(link.children);
                  }}
                >
                  {link.name} <ArrowForwardIcon color={"purple"} />
                </Link>
              ) : (
                <Link href={link.href}>{link.name}</Link>
              )
            )}
          </VStack>
        </Box>
        <Box pos="absolute" bottom={0} left={0} w="100%">
          <Divider />
          <HStack px={4} py={2}>
            <Button
              colorScheme="purple"
              variant={"outline"}
              as="a"
              href={contactLink}
            >
              Contact
            </Button>
            <Button colorScheme="purple" as="a" href={donateLink}>
              Donate
            </Button>
          </HStack>
          <Divider />
          <HStack px={4} py={2} alignItems={"center"}>
            <Text fontSize={"lg"}>Follow us:</Text>
            {socialMedia.map((s) => (
              <Link href={s.href} lineHeight={0}>
                <Icon color="purple.500" as={s.icon} />
              </Link>
            ))}
          </HStack>
        </Box>
      </DrawerContent>
    </Box>
  );
};
