import {
  Box,
  Container,
  HStack,
  Heading,
  VStack,
  Text,
  Flex,
  GridItem,
  Grid,
  Link,
  Icon,
} from "@chakra-ui/react";
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { links, socialMedia } from "./NavBar";
import SignUpForm from "./SignUpForm";

export default function Footer() {
  return (
    <>
      <Box bg="gray.100">
        <Container maxW="7xl" pt={4} pb={4}>
          <Flex
            justifyContent={"space-between"}
            flexDirection={{ base: "column", lg: "row" }}
          >
            <VStack alignItems={"left"} spacing={2}>
              <Heading size="lg">Stay in the loop</Heading>
              <Text>
                Get notified about important updates, volunteer opportunities,
                etc.
              </Text>
            </VStack>
            <SignUpForm />
            {/* <LinearForm
              fields={[
                {
                  name: "name",
                  default: "Name",
                  width: "150px",
                },
                {
                  name: "email",
                  default: "Email",
                  width: "150px",
                },
              ]}
              buttonText={"Subscribe"}
            /> */}
          </Flex>
        </Container>
      </Box>
      <Box bg="purple.800" color="white">
        <Container maxW="7xl" fontSize={"md"}>
          <Grid
            templateColumns={{
              base: "1fr",
              md: "1.5fr 1fr 1fr ",
              lg: "1.5fr 1fr 1fr 1fr",
            }}
            py={5}
            gap={{ base: 3, md: 5 }}
          >
            <GridItem>
              <Text>
                Our mission is to promote the dignity and wellbeing for anyone
                experiencing poverty in Calgary by offering hygiene and
                wraparound services through radical hospitality and compassion.
              </Text>
            </GridItem>
            <GridItem>
              <VStack spacing={1} alignItems={"left"}>
                <Text fontWeight="bold">Navigation</Text>
                {links.map((link) => {
                  if (!link.children) {
                    return <Link href={link.href}>{link.name}</Link>;
                  } else {
                    return link.children.map((child) => {
                      return <Link href={child.href}>{child.name}</Link>;
                    });
                  }
                })}
              </VStack>
            </GridItem>
            <GridItem>
              <VStack spacing={1} alignItems={"left"}>
                <Text fontWeight="bold">Related Links</Text>
                <Link href="https://www.radicareventures.ca/">
                  RadiCare Ventures
                </Link>
                <Link href="https://c-pucv.ca/">C-PUCV</Link>
                <Link href="https://lavamaex.org/">LavaMaeX</Link>
              </VStack>
            </GridItem>
            <GridItem>
              <VStack spacing={1} alignItems={"left"}>
                <Text fontWeight="bold">Contact</Text>
                <HStack>
                  <FaEnvelope />
                  <Link href="mailto:info@radicalgary.ca">
                    info@radicalgary.ca
                  </Link>
                </HStack>
                <HStack>
                  <FaPhoneAlt />
                  <Link href="tel:4034777280">(403) 477-7280</Link>
                </HStack>
              </VStack>
            </GridItem>
          </Grid>
        </Container>
      </Box>
      <Box
        bg="purple.800"
        color="white"
        borderTop={"1px solid"}
        borderColor={"purple.700"}
      >
        <Container maxW="7xl" fontSize={"md"}>
          <Flex py={2} justifyContent={"space-between"} flexWrap={"wrap"}>
            <Text fontSize={"sm"}>
              ©2023 RadiCalgary. Website by{" "}
              <Link href="https://www.linkedin.com/in/ronald-jin/">
                Ronald J.
              </Link>{" "}
              and{" "}
              <Link href="https://www.linkedin.com/in/nancy-huynh1/">
                Nancy H.
              </Link>
            </Text>
            <HStack>
              <Text fontSize={"sm"}>Follow Us:</Text>
              {socialMedia.map((s) => (
                <Link href={s.href} lineHeight={0}>
                  <Icon as={s.icon} />
                </Link>
              ))}
            </HStack>
          </Flex>
        </Container>
      </Box>
    </>
  );
}
